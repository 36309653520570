<template>
  <div class="col">
    <!-- feedback table  -->

    <!-- <server-table
      :count="count"
      :DataItems="usersData"
      @Refresh="Refresh"
      v-loading="$store.getters['feedback/load']"
      :title="$t('feedback')"
      :useFilter="false"
      :enable_export="false"
    >
      <template #columns>
        <el-table-column
          prop="body"
          sortable
          :label="$t('feedback')"
          min-width="120"
        >
        </el-table-column>

        <el-table-column
          prop="course_name"
          sortable
          :label="$t('Global.course_name')"
          min-width="120"
        >
        <template slot-scope="scope">
            {{ getCourseTitle(scope.row.course_name) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="activity_name"
          sortable
           :label="$t('activity')"
          min-width="120"
        >
        </el-table-column>

        <el-table-column prop="" label="_" width="180">
          <template slot-scope="scope">
            <div class="td-actions">
              <el-button
                class="border-0 px-2"
                v-tooltip.top-center="$t('edit_eval_data')"
                @click="open_edit_dialog(scope.row)"
                ><i class="fa fa-edit text-warning"></i>
              </el-button>

              <el-button
                v-tooltip.top-center="$t('delete_eval')"
                @click="remove(scope.row.id)"
                class="btn btn-danger btn-link btn-xs px-2"
              >
                <i class="fa fa-trash"></i>
              </el-button>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #action>
        <button
          @click="open_edit_dialog({})"
          class="border-0 text-dark"
          style="background: transparent"
        >
          <el-icon class="el-icon-plus"></el-icon>
          {{$t("Add_Suggestion")}}
        </button>
      </template>
    </server-table> -->

    <!-- edit & add feedback dialog  -->
    <el-dialog
      :title="$t('edit_eval')"
      width="70%"
      :visible.sync="feedbackDialog"
      top="4vh"
      class="text-right"
    >
      <el-form
        :model="modifyFeedback"
        ref="feedbackFormRef"
        :rules="feedbackRules"
        label-position="top"
        v-loading="$store.getters['feedback/load']"
      >
        <el-row :gutter="20">

          <el-col :span="24">

            <el-form-item :label="$t('courses')" prop="parent_id">
              <!-- parent_id is the common prop between courses and feedback -->
              <el-select
                v-model="modifyFeedback.parent_id"
                :placeholder="$t('courses')"
                style="width: 100%"
                @change="set_feedback_lessons"
              >
                <el-option
                  v-for="(item, index) in courses"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item :label="$t('activities')">
              <el-select
                :disabled="lessons.length == 0"
                v-model="modifyFeedback.course_id"
                :placeholder="$t('activities')"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in lessons"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item :label="$t('evaluation')" prop="body">
              <el-input
                v-model="modifyFeedback.body"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item :label="$t('message')" prop="message">
              <el-input
                type="textarea"
                v-model="modifyFeedback.message"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="
          dialog-footer
          w-50
          flex-column flex-lg-row
          d-md-flex
          my-3
          mr-auto
        "
        dir="ltr"
      >
        <el-button
          class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
          @click="feedbackDialog = false"
          >{{$t("cancel")}}
        </el-button>
        <el-button
          class="
            text-center
            px-4 px-lg-3
            ml-lg-2
            mx-auto
            d-block
            text-capitalize
            w-100
            my-1
          "
          type="primary"
          @click="saveChanges"
          >{{$t("save")}} </el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import Swal from "sweetalert2";

export default {
  name: "index",
  components: { ServerTable },
  data() {
    return {
      // Edit Feedback data
      modifyFeedback: {},
      feedbackDialog: false,
      lessons: [],
      feedbackRules: {
        body: [
          {
            required: true,
            message: this.$t("feedback_required"),
            trigger: "blur",
          },
        ],
        parent_id: [
          {
            required: true,
            message: this.$t("course_name_required"),
            trigger: "change",
          },
        ],

        message: [
          {
            required: true,
            message: this.$t("message_required"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    usersData() {
      return this.$store.getters["feedback/feedback"];
    },
    count() {
      return this.$store.getters["feedback/total"];
    },
    courses() {
      return this.$store.getters["feedback/courses"];
    },
  },
  methods: {
    getCourseTitle(title) {
      return this.getLocalizedText(title);
    },
    getCourseDescription(description) {
      return this.getLocalizedText(description);
    },
    getLessonTitle(title) {
      return this.getLocalizedText(title);
    },
    getLocalizedText(jsonString) {
      const locale = this.$i18n.locale;
      if (jsonString) {
        try {
          const parsedData = JSON.parse(jsonString);
          console.log("Parsed Data:", parsedData);
          return parsedData[locale] || parsedData["en"]; // Fallback to English if the locale is not found
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return jsonString; // Return the original string if it's not valid JSON
        }
      } else {
        return "";
      }
    },
    Refresh(query) {
      this.$store.dispatch("feedback/list", { query: query });
    },

    remove(id) {
      Swal.fire({
        title: this.$t('confirm_delete_eval'),
        text:  this.$t('ask_delete_eval'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText:  this.$t('confirm'),
        cancelButtonText:  this.$t('cancel'),
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store.dispatch("feedback/delete", id).then((_) => {
            this.Refresh();
            Swal.fire({
              title: this.$t('deleted'),
              text:  this.$t('eval_deleted'),
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          });
        }
      });
    },

    open_edit_dialog(item) {
      this.modifyFeedback = { ...item };
      // remove course to prevent showing as a number not valid activity name
      // It is not eh best solution but the best for now
      delete this.modifyFeedback.course_id;
      this.feedbackDialog = true;
    },

    set_feedback_lessons() {
      // if selected course have lessons, add them to modifyFeedback object to be displayed at edit feedback dialog
      this.lessons = this.courses.filter(
        (course) => course.id === this.modifyFeedback.parent_id
      )[0].lessons;
    },

    saveChanges() {
      this.$refs["feedbackFormRef"].validate((valid) => {
        if (valid) {
          // remove unused properties since it is need at the database and will cause error
          // delete this after validation or the form will not be valid since validation needs parent_id
          delete this.modifyFeedback.parent_id;
          delete this.modifyFeedback.activity_name;
          delete this.modifyFeedback.course_name;
          this.$store
            .dispatch("feedback/save", {
              query: this.modifyFeedback,
              id: this.modifyFeedback.id ? `/${this.modifyFeedback.id}` : "",
            })
            .then((_) => {
              this.Refresh({});
              this.feedbackDialog = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
::v-deep .cell {
  text-align: right;
  word-break: break-word;
}

::v-deep .el-form--label-top .el-form-item__label {
  position: relative;
}

::v-deep .el-form-item__label:before {
  position: absolute;
  left: -12px;
}

::v-deep .el-dialog__headerbtn {
  left: 20px;
  right: unset;
}

::v-deep .el-form-item__error {
  left: unset;
  right: 0;
  font-size: 0.9rem;
}

/* form select inputs  */
.el-select-dropdown__item {
  padding: 5px 40px;
  text-align: right;
}
</style>
